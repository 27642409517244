html, body {
  background-color: #EFEFEF;
}

.App {
  /* text-align: center; */
  background-color: #EFEFEF;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #EFEFEF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Content {
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 1em;
  padding-top: 2.5em;
}

.Header {
  /* padding-right: 3em;
  padding-left: 3em; */
  padding-bottom: 0.3em;
  /* margin-right: 1em;
  margin-left: 1em; */
  /* font-size: calc(10px + 0.5vmin); */
}

.Navbar {
  padding-left: 1.5em;
  padding-right: 3em;
  padding-top: 1.5em;
  
}

.Body {
  /* padding-right: 3em;
  padding-left: 3em;
  margin-right: 1em;
  margin-left: 1em; */
  font-size: 16px;
}

.Body p {
  font-size: 16px;
}

.Footer {
  padding: 4em;
}

.Title {
  font-size: 2em;
  line-height: 2.5rem;
}

#myso {
  height: 30em;
  width: 100%;
}

.Spotify {
  height: 190px;
  width: 100%;
}

/* @media (max-width: 767px) {
  #myso {
    height: 30em;
    width: 100%;
  }
} */

/* .Navbar, .Footer {
  padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
} */

@media (min-width: 768px) {
  .Content {
    padding-right: 10em;
    padding-left: 10em;
    padding-top: 7.5em;
  }

  #myso {
    height:auto;
    width: 50%;
  }

  .Spotify {
    height: 250;
    width: 1;
  }
}

@media (min-width: 1024px) {
  .Content {
    padding-right: 10em;
    padding-left: 10em;
  }
}

@media (min-width: 1280px) {
  .Content {
    padding-right: 20em;
    padding-left: 20em;
  }
}

/* @media (min-width: 1500px) {
  .Content {
    padding-right: 30em;
    padding-left: 30em;

  }
} */

@media (min-width: 1920px) {
  .Content {
    padding-right: 30em;
    padding-left: 30em;
  }
}