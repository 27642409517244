.Record {
  /* flex-basis: 2rem; */
  /* background-color: blue; */
  color: black;


}

.Record-content {
  /* padding-left: 0.1em; */
  /* padding-right: 0.5em; */
  /* font-size: calc(12px + 1vmin); */
  color: black;
}

.Record-name {
  /* padding-left: 0.1em; */
  padding-top: 0.15em;
  padding-bottom: 0.15em;
}

.Record-details {
  /* padding-right: 1em; */
  /* font-size: calc(10px + 0.5vmin); */
}

.Record:hover {
  background-color: #DFDFDF;
  border-radius: 8px;
}